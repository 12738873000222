import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function getRosterFromToKey(state) {
  if (state.rosterFromTo && state.rosterFromTo.from && state.rosterFromTo.to) {
    return state.rosterFromTo.from + " - " + state.rosterFromTo.to;
  } else {
    return "no-key";
  }
}

export default new Vuex.Store({
  state: {
    apiKey: undefined,
    jwt: undefined,
    loggedIn: false,
    validMpLicense: false,
    validMzLicense: false,
    imgCacheTimestamp: Date.now(),
    storeId: localStorage.getItem("selected_store"),
    storeData: {},
    companyMap: {},
    storeMap: {},
    globalFilter: {},
    externalSoftware: 0,
    selectedStores: [],
    companyId: localStorage.getItem("selected_company"),
    businessunits: [],
    rosterFromTo: undefined,
    accountPreference: JSON.parse(localStorage.getItem("accountPreference")),
    permissions: {},
    userInfo: {},
    isSuperUser: false,
    companySettings: {},
    storesSettings: {},
    businessUnitMap: {},
    businessUnitMapState: {},
    skillMap: {},
    skillMapState: {},
    spotMap: {},
    spotMapState: {},
    employeeMap: {},
    employeeMapState: {},
    employeeTypeMapState: {},
    employeeTypeMap: {},
    employeeWeeklyParametersMapState: {},
    employeeWeeklyParametersMap: {},
    employeeTypeTemplateMapState: {},
    employeeTypeTemplateMap: {},
    actualShiftMapState: {},
    actualShiftMap: {},
    actualBreakMapState: {},
    actualBreakMap: {},
    actualShiftMaxState: {},
    actualShiftMax: 0,
    planShiftMapState: {},
    planShiftMap: {},
    employeeRulesTemplateMapState: {},
    employeeRulesTemplateMap: {},
    employeeRulesMap: {},
    employeeRulesMapState: {},
    collectiveContractMap: {},
    collectiveContractMapState: {},
    allowedContractMap: {},
    allowedContractMapState: {},
    employeeContractMap: {},
    employeeContractMapState: {},
    currentMonthEmployeeContractMap: {},
    currentMonthEmployeeContractMapState: {},
    employeePreferenceMap: {},
    employeePreferenceMapState: {},
    employeeSkillMap: {},
    employeeSkillMapState: {},
    employeeAvailabilityMap: {},
    employeeAvailabilityMapState: {},
    employeeWorkbalanceMap: {},
    employeeWorkbalanceMapState: {},
    eventLogMap: {},
    eventLogMapState: {},

    shiftSkillMap: {},
    shiftSkillMapState: {},

    userUnitSortMapState: {},
    userUnitSortMap: {},

    uiDataMap: {},
    uiDataMapState: {},

    countryEnumMap: {},
    countryEnumMapState: {},
    countryStateEnumMap: {},
    countryStateEnumMapState: {},
    genderEnumMap: {},
    genderEnumMapState: {},
    citizentypeEnumMap: {},
    citizentypeEnumMapState: {},
    civilstatusEnumMap: {},
    civilstatusEnumMapState: {},

    holidayMapState: {},
    holidayMap: {},
    activeStoreUsersMap: {},

    externalServicesMap: {},
    employeeExternalDataMap: {},

    crewlaborPlanningMap: {},
    crewlaborPlanningMapState: {},
    planningEmployeeMap: {},
    planningEmployeeMapState: {},
    nightMode: false,
    rosterVisibilityState: {
      visibilityKVErrors: true,
      visibilityPlanErrors: true,
      calculatePastPlanning: false,
      visibilityOverlappingErrors: true,
      visibilityActualShifts: true,
      visibilityPlanShifts: true,
      visibilityUndesired: true,
      visibilityDesired: true,
      visibilityNotAvailable: true,
      visibilityOnlyAvailable: true,
      visibilityVacation: true,
      visibilitySick: true,
      visibilityHolidayAdjustment: true,
      visibilityEducation: true,
      visibilityWageContinuation: true,
      daySpotColorSwitch: false,
      visibilityProductivity: false,
      rosterDailyShiftTimeSort: false
    },
    rosterView: true,
    notificationBellConfigState: {
      showNewEmployees: true,
      showCanceledContracts: true,
      showSocialInsuranceRegistrations: true,
      showMinimalDatafieldsNull: true,
      showIssuesWithImportJobsCount: true,
      showMdPersonnelIdNotUniqueCount: true,
      showEmployeeExportCount: false,
      showUnconfirmedDocuments: true,
      showExpiredDocuments: true,
      showExpiringDocuments: true,
      documentExpiryLimit: 30,
      showMissingPassDocumentsForNonEU: true,
      showMissingWorkPermitDocumentsForNonEU: true,
      showMissingResidencePermitDocumentsForNonEU: true,
      showMissingEducationPermitDocumentsForNonEU: true,
    },
    exportSettingsMap: {},
    exportSettingsMapState: {},
    employeeDocumentMap: {},
    employeeDocumentMapState: {},
  },
  getters: {
    rosterView: (state) => {
      return state.rosterView;
    },
    jwt: (state) => {
      return state.jwt;
    },
    loggedIn: (state) => {
      return state.loggedIn;
    },
    companyId: (state) => {
      return state.companyId;
    },
    storeId: (state) => {
      return state.storeId;
    },
    companyMap: (state) => {
      return state.companyMap ? state.companyMap : {};
    },
    companyArray: (state) => {
      return state.companyMap ? Object.values(state.companyMap) : [];
    },
    storeMap: (state) => {
      return state.storeMap ? state.storeMap : {};
    },
    storeArray: (state) => {
      return state.storeMap ? Object.values(state.storeMap) : [];
    },
    globalFilter: (state) => {
      return state.globalFilter;
    },
    externalSoftware: (state) => {
      return state.externalSoftware;
    },
    selectedStores: (state) => {
      return state.selectedStores ? state.selectedStores : [];
    },
    storeData: (state) => {
      return state.storeData;
    },
    validMpLicense: (state) => {
      return state.validMpLicense;
    },
    validMzLicense: (state) => {
      return state.validMzLicense;
    },
    imgCacheTimestamp: (state) => {
      return state.imgCacheTimestamp;
    },
    rosterFromTo: (state) => {
      if (state.rosterFromTo) {
        return state.rosterFromTo;
      } else {
        return null;
      }
    },
    rosterFromToKey: (state) => {
      if (state.rosterFromTo && state.rosterFromTo.from && state.rosterFromTo.to) {
        return state.rosterFromTo.from + " - " + state.rosterFromTo.to;
      } else {
        return "no-key";
      }
    },
    userInfo: (state) => {
      return state.userInfo;
    },
    accountPreference: (state) => {
      return state.accountPreference;
    },
    permissions: (state) => {
      return state.permissions;
    },
    companySettings: (state) => {
      return state.companySettings;
    },
    storesSettings: (state) => {
      return state.storesSettings;
    },
    isSuperUser: (state) => {
      return state.isSuperUser;
    },
    activeStoreUsersMap: (state) => {
      if (state.activeStoreUsersMap) {
        let map = state.activeStoreUsersMap;
        if (!state.isSuperUser) {
          let filter = ["91bce5c8-6172-43bb-8e6a-721032659d94", "801395ab-001f-42a2-9fb9-dd879323cbba", "13cd4232-d4a9-4632-b169-561bcba58cf6", "d7789f86-fdad-4491-b7f2-75cedfff8858", "1521fa5a-ef75-4aca-93f5-fa912e9f0f6d"];
          Object.values(map)
            .filter((u) => filter.find((sid) => u.id === sid))
            .forEach((u) => (u.hidden = true));
        }
        return map;
      } else {
        return {};
      }
    },
    businessUnitMap: (state) => {
      return state.businessUnitMap ? state.businessUnitMap : {};
    },
    businessUnitArray: (state) => {
      return state.businessUnitMap ? Object.values(state.businessUnitMap) : [];
    },
    businessUnitById: (state) => (id) => {
      if (state.businessUnitMap) {
        return state.businessUnitMap[id];
      } else {
        return null;
      }
    },
    skillMap: (state) => {
      return state.skillMap ? state.skillMap : {};
    },
    skillArray: (state) => {
      return state.skillMap ? Object.values(state.skillMap) : [];
    },
    skillById: (state) => (id) => {
      if (state.skillMap) {
        return state.skillMap[id];
      } else {
        return null;
      }
    },
    spotMap: (state) => {
      return state.spotMap ? state.spotMap : {};
    },
    spotArray: (state) => {
      return state.spotMap ? Object.values(state.spotMap) : [];
    },
    spotById: (state) => (id) => {
      if (state.spotMap) {
        return state.spotMap[id];
      } else {
        return null;
      }
    },
    employeeMap: (state) => {
      return state.employeeMap ? state.employeeMap : {};
    },
    employeeArray: (state) => {
      return state.employeeMap ? Object.values(state.employeeMap) : [];
    },
    employeeById: (state) => (id) => {
      if (state.employeeMap) {
        return state.employeeMap[id];
      } else {
        return null;
      }
    },
    planShiftMap: (state) => {
      return state.planShiftMap ? state.planShiftMap : {};
    },
    planShiftArray: (state) => {
      return state.planShiftMap ? Object.values(state.planShiftMap) : [];
    },
    planShiftById: (state) => (id) => {
      if (state.planShiftMap) {
        return state.planShiftMap[id];
      } else {
        return null;
      }
    },
    employeeRulesTemplateMap: (state) => {
      return state.employeeRulesTemplateMap ? state.employeeRulesTemplateMap : {};
    },
    employeeRulesTemplateArray: (state) => {
      return state.employeeRulesTemplateMap ? Object.values(state.employeeRulesTemplateMap) : [];
    },
    employeeRulesMap: (state) => {
      return state.employeeRulesMap ? state.employeeRulesMap : {};
    },
    employeeRulesArray: (state) => {
      return state.employeeRulesMap ? Object.values(state.employeeRulesMap) : [];
    },
    employeeTypeMap: (state) => {
      return state.employeeTypeMap ? state.employeeTypeMap : {};
    },
    employeeTypeArray: (state) => {
      return state.employeeTypeMap ? Object.values(state.employeeTypeMap) : [];
    },
    employeeWeeklyParametersMap: (state) => {
      return state.employeeWeeklyParametersMap ? state.employeeWeeklyParametersMap : {};
    },
    employeeWeeklyParametersArray: (state) => {
      return state.employeeWeeklyParametersMap ? Object.values(state.employeeWeeklyParametersMap) : [];
    },
    employeeWeeklyParametersMapState: (state) => {
      return state.employeeWeeklyParametersMapState ? state.employeeWeeklyParametersMapState : {};
    },
    employeeTypeById: (state) => (id) => {
      if (state.employeeTypeMap) {
        return state.employeeTypeMap[id];
      } else {
        return null;
      }
    },
    employeeRulesById: (state) => (id) => {
      if (state.employeeRulesMap) {
        return state.employeeRulesMap[id];
      } else {
        return null;
      }
    },
    employeeTypeTemplateMap: (state) => {
      return state.employeeTypeTemplateMap ? state.employeeTypeTemplateMap : {};
    },
    employeeTypeTemplateArray: (state) => {
      return state.employeeTypeTemplateMap ? Object.values(state.employeeTypeTemplateMap) : [];
    },
    employeeTypeTemplateById: (state) => (id) => {
      if (state.employeeTypeTemplateMap) {
        return state.employeeTypeTemplateMap[id];
      } else {
        return null;
      }
    },
    employeeRulesTemplateById: (state) => (id) => {
      if (state.employeeRulesTemplateMap) {
        return state.employeeRulesTemplateMap[id];
      } else {
        return null;
      }
    },
    actualShiftMap: (state) => {
      return state.actualShiftMap ? state.actualShiftMap : {};
    },
    actualShiftArray: (state) => {
      return state.actualShiftMap ? Object.values(state.actualShiftMap) : [];
    },
    actualShiftById: (state) => (id) => {
      if (state.actualShiftMap) {
        return state.actualShiftMap[id];
      } else {
        return null;
      }
    },
    actualBreakMap: (state) => {
      return state.actualBreakMap ? state.actualBreakMap : {};
    },
    actualBreakArray: (state) => {
      return state.actualBreakMap ? Object.values(state.actualBreakMap) : [];
    },
    actualBreakById: (state) => (id) => {
      if (state.actualBreakMap) {
        return state.actualBreakMap[id];
      } else {
        return null;
      }
    },
    actualShiftMax: (state) => {
      return state.actualShiftMax ? state.actualShiftMax : 0;
    },
    userUnitSortMap: (state) => {
      return state.userUnitSortMap ? state.userUnitSortMap : {};
    },
    holidayMap: (state) => {
      return state.holidayMap ? state.holidayMap : {};
    },
    collectiveContractMap: (state) => {
      return state.collectiveContractMap ? state.collectiveContractMap : {};
    },
    collectiveContractArray: (state) => {
      return state.collectiveContractMap ? Object.values(state.collectiveContractMap) : [];
    },
    allowedContractMap: (state) => {
      return state.allowedContractMap ? state.allowedContractMap : {};
    },
    allowedContractArray: (state) => {
      return state.allowedContractMap ? Object.values(state.allowedContractMap) : [];
    },
    shiftSkillMap: (state) => {
      return state.shiftSkillMap ? state.shiftSkillMap : {};
    },
    shiftSkillArray: (state) => {
      return state.shiftSkillMap ? Object.values(state.shiftSkillMap) : [];
    },
    employeeContractMap: (state) => {
      return state.employeeContractMap ? state.employeeContractMap : {};
    },
    employeeContractArray: (state) => {
      return state.employeeContractMap ? Object.values(state.employeeContractMap) : [];
    },
    currentMonthEmployeeContractMap: (state) => {
      return state.currentMonthEmployeeContractMap ? state.currentMonthEmployeeContractMap : {};
    },
    currentMonthEmployeeContractArray: (state) => {
      return state.currentMonthEmployeeContractMap ? Object.values(state.currentMonthEmployeeContractMap) : [];
    },
    employeePreferenceMap: (state) => {
      return state.employeePreferenceMap ? state.employeePreferenceMap : {};
    },
    employeePreferenceArray: (state) => {
      return state.employeePreferenceMap ? Object.values(state.employeePreferenceMap) : [];
    },
    employeeSkillMap: (state) => {
      return state.employeeSkillMap ? state.employeeSkillMap : {};
    },
    employeeSkillArray: (state) => {
      return state.employeeSkillMap ? Object.values(state.employeeSkillMap) : [];
    },
    employeeWorkbalanceMap: (state) => {
      return state.employeeWorkbalanceMap ? state.employeeWorkbalanceMap : {};
    },
    employeeWorkbalanceArray: (state) => {
      return state.employeeWorkbalanceMap ? Object.values(state.employeeWorkbalanceMap) : [];
    },
    employeeAvailabilityMap: (state) => {
      return state.employeeAvailabilityMap ? state.employeeAvailabilityMap : {};
    },
    employeeAvailabilityArray: (state) => {
      return state.employeeAvailabilityMap ? Object.values(state.employeeAvailabilityMap) : [];
    },
    eventLogMap: (state) => {
      return state.eventLogMap ? state.eventLogMap : {};
    },
    eventLogArray: (state) => {
      return state.eventLogMap ? Object.values(state.eventLogMap) : [];
    },
    uiDataMap: (state) => {
      return state.uiDataMap ? state.uiDataMap : {};
    },
    countryEnumMap: (state) => {
      return state.countryEnumMap ? state.countryEnumMap : {};
    },
    countryStateEnumMap: (state) => {
      return state.countryStateEnumMap ? state.countryStateEnumMap : {};
    },
    genderEnumMap: (state) => {
      return state.genderEnumMap ? state.genderEnumMap : {};
    },
    citizentypeEnumMap: (state) => {
      return state.citizentypeEnumMap ? state.citizentypeEnumMap : {};
    },
    civilstatusEnumMap: (state) => {
      return state.civilstatusEnumMap ? state.civilstatusEnumMap : {};
    },
    externalServicesMap: (state) => {
      return state.externalServicesMap ? state.externalServicesMap : {};
    },
    externalServicesArray: (state) => {
      return state.externalServicesMap ? Object.values(state.externalServicesMap) : [];
    },
    employeeExternalDataMap: (state) => {
      return state.employeeExternalDataMap ? state.employeeExternalDataMap : {};
    },
    employeeExternalDataArray: (state) => {
      return state.employeeExternalDataMap ? Object.values(state.employeeExternalDataMap) : [];
    },
    crewlaborPlanningMap: (state) => {
      return state.crewlaborPlanningMap ? state.crewlaborPlanningMap : {};
    },
    planningEmployeeMap: (state) => {
      return state.planningEmployeeMap ? state.planningEmployeeMap : {};
    },
    nightMode: (state) => {
      return state.nightMode;
    },
    rosterVisibilityState: (state) => {
      let rosterVisibilityState = localStorage.getItem("rosterVisibilityState");
      if (!rosterVisibilityState) {
        rosterVisibilityState = {};
      } else {
        rosterVisibilityState = JSON.parse(rosterVisibilityState);
      }

      let defaultValues = state.rosterVisibilityState;

      let deprecatedKeys = Object.keys(rosterVisibilityState).filter((savedKey) => !Object.keys(defaultValues).find((defaultKey) => defaultKey === savedKey));
      deprecatedKeys.forEach((key) => delete rosterVisibilityState[key]);

      for (let key in defaultValues) {
        if (!rosterVisibilityState.hasOwnProperty(key)) rosterVisibilityState[key] = defaultValues[key];
      }

      localStorage.setItem("rosterVisibilityState", JSON.stringify(rosterVisibilityState));
      state.rosterVisibilityState = rosterVisibilityState;
      return state.rosterVisibilityState;
    },
    notificationBellConfigState: (state) => {
      let notificationBellConfigState = localStorage.getItem("notificationBellConfigState");
      if (!notificationBellConfigState) {
        notificationBellConfigState = {};
      } else {
        notificationBellConfigState = JSON.parse(notificationBellConfigState);
      }
      let defaultValues = state.notificationBellConfigState;
      let deprecatedKeys = Object.keys(notificationBellConfigState).filter((savedKey) => !Object.keys(defaultValues).find((defaultKey) => defaultKey === savedKey));
      deprecatedKeys.forEach((key) => delete notificationBellConfigState[key]);

      for (let key in defaultValues) {
        if (!notificationBellConfigState.hasOwnProperty(key)) notificationBellConfigState[key] = defaultValues[key];
      }
      state.notificationBellConfigState = notificationBellConfigState;
      return state.notificationBellConfigState;
    },
    exportSettingsMap: state => {
      return state.exportSettingsMap ? state.exportSettingsMap : {};
    },
    employeeDocumentMap: state => {
      return state.employeeDocumentMap ? state.employeeDocumentMap : {};
    }
  },
  mutations: {
    setRosterView: function (state, payload) {
      state.rosterView = payload.rosterView;
    },
    setImgCacheTimestamp: function (state, payload) {
      state.imgCacheTimestamp = payload.imgCacheTimestamp;
    },
    setUserInfo: function (state, payload) {
      state.userInfo = payload.userInfo;
    },
    setApiKey: function (state, payload) {
      state.apiKey = payload.apiKey;
    },
    setStoreId: function (state, payload) {
      state.storeId = payload.storeId;
      if (payload.storeId != null) {
        localStorage.setItem("selected_store", payload.storeId);
      }
    },
    setCompanyMap: function (state, payload) {
      state.companyMap = payload.companyMap;
    },
    setStoreMap: function (state, payload) {
      state.storeMap = payload.storeMap;
    },
    setGlobalFilter: function (state, payload) {
      state.globalFilter = payload.globalFilter;
    },
    setExternalSoftware: function (state, payload) {
      state.externalSoftware = payload.software;
    },
    setSelectedStores: function (state, payload) {
      state.selectedStores = payload.selectedStores;
    },
    setCompanyData: function (state, payload) {
      state.companyData = payload.companyData;
    },
    setStoreData: function (state, payload) {
      state.storeData = payload.storeData;
    },
    setCompanyId: function (state, payload) {
      state.companyId = payload.companyId;
      if (payload.companyId != null) {
        localStorage.setItem("selected_company", payload.companyId);
      } else {
        console.log("setCompanyId - Payload is null - Login incorrect? Resetting localStorage data!");
      }
    },
    setJWT: function (state, payload) {
      state.jwt = payload.jwt;
      //localStorage.setItem("md_token", payload.jwt);
    },
    setLoggedIn: function (state, payload) {
      state.loggedIn = payload.loggedIn;
    },
    setValidMpLicense: function (state, payload) {
      state.validMpLicense = payload.validMpLicense;
    },
    setValidMzLicense: function (state, payload) {
      state.validMzLicense = payload.validMzLicense;
    },
    setIsSuperUser: function (state, payload) {
      state.isSuperUser = payload.isSuperUser;
    },
    setAccountPreference: function (state, payload) {
      state.accountPreference = payload;
      localStorage.setItem("accountPreference", JSON.stringify(payload));
    },
    clearJWT: function (state) {
      state.jwt = null;
      localStorage.removeItem("md_token");
    },
    setActiveStoreUsersMap: function (state, payload) {
      state.activeStoreUsersMap = Object.assign({}, payload.activeStoreUsersMap);
    },
    setActiveStoreUsersMapEntry: function (state, payload) {
      if (state.activeStoreUsersMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.activeStoreUsersMap = Object.assign({}, state.activeStoreUsersMap, entry);
      }
    },
    deleteActiveStoreUsersMapEntry: function (state, payload) {
      if (state.activeStoreUsersMap) {
        delete state.activeStoreUsersMap[payload.id];
        state.activeStoreUsersMap = Object.assign({}, state.activeStoreUsersMap);
      }
    },
    setRosterFromTo: function (state, payload) {
      state.rosterFromTo = Object.assign({}, payload.rosterFromTo);
    },
    setPermissions: function (state, payload) {
      state.permission = Object.assign({}, payload.permissions);
    },
    setCompanySettings: function (state, payload) {
      state.companySettings = Object.assign({}, payload.companySettings);
    },
    setStoresSettings: function (state, payload) {
      state.storesSettings = Object.assign({}, payload.storesSettings);
    },
    setBusinessUnitMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };

      state.businessUnitMapState = tstate;
      state.businessUnitMap = Object.assign({}, payload.businessUnitMap);
    },
    setBusinessUnitMapEntry: function (state, payload) {
      if (state.businessUnitMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.businessUnitMap = Object.assign({}, state.businessUnitMap, entry);
      }
    },
    deleteBusinessUnitMapEntry: function (state, payload) {
      if (state.businessUnitMap) {
        delete state.businessUnitMap[payload.id];
        state.businessUnitMap = Object.assign({}, state.businessUnitMap);
      }
    },
    setSkillMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.skillMapState = tstate;
      state.skillMap = Object.assign({}, payload.skillMap);
    },
    setSkillMapEntry: function (state, payload) {
      if (state.skillMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.skillMap = Object.assign({}, state.skillMap, entry);
      }
    },
    deleteSkillMapEntry: function (state, payload) {
      if (state.skillMap) {
        delete state.skillMap[payload.id];
        state.skillMap = Object.assign({}, state.skillMap);
      }
    },
    setSpotMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };

      state.spotMapState = tstate;
      state.spotMap = Object.assign({}, payload.spotMap);
    },
    setSpotMapEntry: function (state, payload) {
      if (state.spotMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.spotMap = Object.assign({}, state.spotMap, entry);
      }
    },
    deleteSpotMapEntry: function (state, payload) {
      if (state.spotMap) {
        delete state.spotMap[payload.id];
        state.spotMap = Object.assign({}, state.spotMap);
      }
    },
    setEmployeeMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId,
        rosterFromToKey: getRosterFromToKey(state),
        type: payload.type
      };
      state.employeeMapState = tstate;
      state.employeeMap = Object.assign({}, payload.employeeMap);
    },
    setEmployeeMapEntry: function (state, payload) {
      if (state.employeeMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeMap = Object.assign({}, state.employeeMap, entry);
      }
    },
    deleteEmployeeMapEntry: function (state, payload) {
      if (state.employeeMap) {
        delete state.employeeMap[payload.id];
        state.employeeMap = Object.assign({}, state.employeeMap);
      }
    },
    setEmployeeTypeMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
        /* rosterFromToKey: getRosterFromToKey(state), */
      };
      state.employeeTypeMapState = tstate;
      state.employeeTypeMap = Object.assign({}, payload.employeeTypeMap);
    },
    setEmployeeTypeMapEntry: function (state, payload) {
      if (state.employeeTypeMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeTypeMap = Object.assign({}, state.employeeTypeMap, entry);
      }
    },
    deleteEmployeeTypeMapEntry: function (state, payload) {
      if (state.employeeTypeMap) {
        delete state.employeeTypeMap[payload.id];
        state.employeeTypeMap = Object.assign({}, state.employeeTypeMap);
      }
    },
    setEmployeeWeeklyParametersMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.employeeWeeklyParametersMapState = tstate;
      state.employeeWeeklyParametersMap = Object.assign({}, payload.employeeWeeklyParametersMap);
    },
    setEmployeeWeeklyParametersMapEntry: function (state, payload) {
      if (state.employeeWeeklyParametersMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeWeeklyParametersMap = Object.assign({}, state.employeeWeeklyParametersMap, entry);
      }
    },
    deleteEmployeeWeeklyParametersMapEntry: function (state, payload) {
      if (state.employeeWeeklyParametersMap) {
        delete state.employeeWeeklyParametersMap[payload.id];
        state.employeeWeeklyParametersMap = Object.assign({}, state.employeeWeeklyParametersMap);
      }
    },
    setEmployeeTypeTemplateMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.employeeTypeTemplateMapState = tstate;
      state.employeeTypeTemplateMap = Object.assign({}, payload.employeeTypeTemplateMap);
    },
    setEmployeeTypeTemplateMapEntry: function (state, payload) {
      if (state.employeeTypeTemplateMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeTypeTemplateMap = Object.assign({}, state.employeeTypeTemplateMap, entry);
      }
    },
    deleteEmployeeTypeTemplateMapEntry: function (state, payload) {
      if (state.employeeTypeTemplateMap) {
        delete state.employeeTypeTemplateMap[payload.id];
        state.employeeTypeTemplateMap = Object.assign({}, state.employeeTypeTemplateMap);
      }
    },
    setActualShiftMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.actualShiftMapState = tstate;
      state.actualShiftMap = Object.assign({}, payload.actualShiftMap);
    },
    setActualShiftMapEntry: function (state, payload) {
      if (state.actualShiftMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.actualShiftMap = Object.assign({}, state.actualShiftMap, entry);
      }
    },
    deleteActualShiftMapEntry: function (state, payload) {
      if (state.actualShiftMap) {
        delete state.actualShiftMap[payload.id];
        state.actualShiftMap = Object.assign({}, state.actualShiftMap);
      }
    },
    setActualBreakMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.actualBreakMapState = tstate;
      state.actualBreakMap = Object.assign({}, payload.actualBreakMap);
    },
    setActualBreakMapEntry: function (state, payload) {
      if (state.actualBreakMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.actualBreakMap = Object.assign({}, state.actualBreakMap, entry);
      }
    },
    deleteActualBreakMapEntry: function (state, payload) {
      if (state.actualBreakMap) {
        delete state.actualBreakMap[payload.id];
        state.actualBreakMap = Object.assign({}, state.actualBreakMap);
      }
    },
    setActualShiftMax: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.actualShiftMaxState = tstate;
      state.actualShiftMax = payload.actualShiftMax;
    },
    setPlanShiftMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.planShiftMapState = tstate;
      state.planShiftMap = Object.assign({}, payload.planShiftMap);
    },
    setPlanShiftMapEntry: function (state, payload) {
      if (state.planShiftMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.planShiftMap = Object.assign({}, state.planShiftMap, entry);
      }
    },
    deletePlanShiftMapEntry: function (state, payload) {
      if (state.planShiftMap) {
        delete state.planShiftMap[payload.id];
        state.planShiftMap = Object.assign({}, state.planShiftMap);
      }
    },
    setEmployeeRulesTemplateMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.employeeRulesTemplateMapState = tstate;
      state.employeeRulesTemplateMap = Object.assign({}, payload.employeeRulesTemplateMap);
    },
    setEmployeeRulesTemplateMapEntry: function (state, payload) {
      if (state.employeeRulesMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeRulesTemplateMap = Object.assign({}, state.employeeRulesTemplateMap, entry);
      }
    },
    deleteEmployeeRulesTemplateMapEntry: function (state, payload) {
      if (state.employeeRulesTemplateMap) {
        delete state.employeeRulesTemplateMap[payload.id];
        state.employeeRulesTemplateMap = Object.assign({}, state.employeeRulesTemplateMap);
      }
    },
    setEmployeeRulesMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.employeeRulesMapState = tstate;
      state.employeeRulesMap = Object.assign({}, payload.employeeRulesMap);
    },
    setEmployeeRulesMapEntry: function (state, payload) {
      if (state.employeeRulesMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeRulesMap = Object.assign({}, state.employeeRulesMap, entry);
      }
    },
    deleteEmployeeRulesMapEntry: function (state, payload) {
      if (state.employeeRulesMap) {
        delete state.employeeRulesMap[payload.id];
        state.employeeRulesMap = Object.assign({}, state.employeeRulesMap);
      }
    },
    setCollectiveContractMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.collectiveContractMapState = tstate;
      state.collectiveContractMap = Object.assign({}, payload.collectiveContractMap);
    },
    setCollectiveContractMapEntry: function (state, payload) {
      if (state.collectiveContractMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.collectiveContractMap = Object.assign({}, state.collectiveContractMap, entry);
      }
    },
    deleteCollectiveContractMapEntry: function (state, payload) {
      if (state.collectiveContractMap) {
        delete state.collectiveContractMap[payload.id];
        state.collectiveContractMap = Object.assign({}, state.collectiveContractMap);
      }
    },
    setAllowedContractMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.allowedContractMapState = tstate;
      state.allowedContractMap = Object.assign({}, payload.allowedContractMap);
    },
    setAllowedContractMapEntry: function (state, payload) {
      if (state.allowedContractMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.allowedContractMap = Object.assign({}, state.allowedContractMap, entry);
      }
    },
    deleteAllowedContractMapEntry: function (state, payload) {
      if (state.allowedContractMap) {
        delete state.allowedContractMap[payload.id];
        state.allowedContractMap = Object.assign({}, state.allowedContractMap);
      }
    },
    setShiftSkillMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.shiftSkillMapState = tstate;
      state.shiftSkillMap = Object.assign({}, payload.shiftSkillMap);
    },
    setEmployeeContractMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.employeeContractMapState = tstate;
      state.employeeContractMap = Object.assign({}, payload.employeeContractMap);
    },
    setEmployeeContractMapEntry: function (state, payload) {
      if (state.employeeContractMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeContractMap = Object.assign({}, state.employeeContractMap, entry);
      }
    },
    deleteEmployeeContractMapEntry: function (state, payload) {
      if (state.employeeContractMap) {
        delete state.employeeContractMap[payload.id];
        state.employeeContractMap = Object.assign({}, state.employeeContractMap);
      }
    },
    setCurrentMonthEmployeeContractMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state)
      };
      state.currentMonthEmployeeContractMapState = tstate;
      state.currentMonthEmployeeContractMap = Object.assign({}, payload.currentMonthEmployeeContractMap);
    },
    setCurrentMonthEmployeeContractMapEntry: function (state, payload) {
      if (state.currentMonthEmployeeContractMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.currentMonthEmployeeContractMap = Object.assign({}, state.currentMonthEmployeeContractMap, entry);
      }
    },
    deleteCurrentMonthEmployeeContractMapEntry: function (state, payload) {
      if (state.currentMonthEmployeeContractMap) {
        delete state.currentMonthEmployeeContractMap[payload.id];
        state.currentMonthEmployeeContractMap = Object.assign({}, state.currentMonthEmployeeContractMap);
      }
    },
    setEmployeePreferenceMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state),
        type: payload.type
      };
      state.employeePreferenceMapState = tstate;
      state.employeePreferenceMap = Object.assign({}, payload.employeePreferenceMap);
    },
    setEmployeePreferenceMapEntry: function (state, payload) {
      if (state.employeePreferenceMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeePreferenceMap = Object.assign({}, state.employeePreferenceMap, entry);
      }
    },
    deleteEmployeePreferenceMapEntry: function (state, payload) {
      if (state.employeePreferenceMap) {
        delete state.employeePreferenceMap[payload.id];
        state.employeePreferenceMap = Object.assign({}, state.employeePreferenceMap);
      }
    },
    setEmployeeWorkbalanceMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        rosterFromToKey: getRosterFromToKey(state),
        type: payload.type
      };
      state.employeeWorkbalanceMapState = tstate;
      state.employeeWorkbalanceMap = Object.assign({}, payload.employeeWorkbalanceMap);
    },
    setEmployeeWorkbalanceMapEntry: function (state, payload) {
      if (state.employeeWorkbalanceMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeWorkbalanceMap = Object.assign({}, state.employeeWorkbalanceMap, entry);
      }
    },
    setEmployeeSkillMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        type: payload.type
      };
      state.employeeSkillMapState = tstate;
      state.employeeSkillMap = Object.assign({}, payload.employeeSkillMap);
    },
    setEmployeeSkillMapEntry: function (state, payload) {
      if (state.employeeSkillMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeSkillMap = Object.assign({}, state.employeeSkillMap, entry);
      }
    },
    deleteEmployeeSkillMapEntry: function (state, payload) {
      if (state.employeeSkillMap) {
        delete state.employeeSkillMap[payload.id];
        state.employeeSkillMap = Object.assign({}, state.employeeSkillMap);
      }
    },
    setEmployeeAvailabilityMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        type: payload.type
      };
      state.employeeAvailabilityMapState = tstate;
      state.employeeAvailabilityMap = Object.assign({}, payload.employeeAvailabilityMap);
    },
    setEmployeeAvailabilityMapEntry: function (state, payload) {
      if (state.employeeAvailabilityMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeAvailabilityMap = Object.assign({}, state.employeeAvailabilityMap, entry);
      }
    },
    deleteEmployeeAvailabilityMapEntry: function (state, payload) {
      if (state.employeeAvailabilityMap) {
        delete state.employeeAvailabilityMap[payload.id];
        state.employeeAvailabilityMap = Object.assign({}, state.employeeAvailabilityMap);
      }
    },
    setEventLogMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        timestamp: new Date().getTime,
        validity: payload.validity ? payload.validity : 60000
      };
      state.eventLogMapState = tstate;
      state.eventLogMap = Object.assign({}, payload.eventLogMap);
    },
    setUserUnitSortMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.userUnitSortMapState = tstate;
      state.userUnitSortMap = Object.assign({}, payload.userUnitSortMap);
    },
    setUiDataMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.uiDataMapState = tstate;
      state.uiDataMap = Object.assign({}, payload.uiDataMap);
    },
    setCountryEnumMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.countryEnumMapState = tstate;
      state.countryEnumMap = Object.assign({}, payload.countryEnumMap);
    },
    setCountryStateEnumMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.countryStateEnumMapState = tstate;
      state.countryStateEnumMap = Object.assign({}, payload.countryStateEnumMap);
    },
    setGenderEnumMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.genderEnumMapState = tstate;
      state.genderEnumMap = Object.assign({}, payload.genderEnumMap);
    },
    setCitizentypeEnumMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.citizentypeEnumMapState = tstate;
      state.citizentypeEnumMap = Object.assign({}, payload.citizentypeEnumMap);
    },
    setCivilstatusEnumMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId
      };
      state.civilstatusEnumMapState = tstate;
      state.civilstatusEnumMap = Object.assign({}, payload.civilstatusEnumMap);
    },
    setHolidayMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.holidayMapState = tstate;
      state.holidayMap = Object.assign({}, payload.holidayMap);
    },
    setEmployeeExternalDataMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.employeeExternalDataMapState = tstate;
      state.employeeExternalDataMap = Object.assign({}, payload.employeeExternalDataMap);
    },
    setExternalServicesMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId
      };
      state.externalServicesMapState = tstate;
      state.externalServicesMap = Object.assign({}, payload.externalServicesMap);
    },
    setCrewlaborPlanningMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        groupId: payload.groupId,
        year: payload.year
      };
      state.crewlaborPlanningMapState = tstate;
      state.crewlaborPlanningMap = Object.assign({}, payload.crewlaborPlanningMap);
    },
    setCrewlaborPlanningMapEntry: function (state, payload) {
      if (state.crewlaborPlanningMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.crewlaborPlanningMap = Object.assign({}, state.crewlaborPlanningMap, entry);
      }
    },
    setPlanningEmployeeMap: function (state, payload) {
      let tstate = {
        storeId: state.storeId,
        groupId: payload.groupId,
        year: payload.year
      };
      state.planningEmployeeMapState = tstate;
      state.planningEmployeeMap = Object.assign({}, payload.planningEmployeeMap);
    },
    setPlanningEmployeeMapEntry: function (state, payload) {
      if (state.planningEmployeeMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.planningEmployeeMap = Object.assign({}, state.planningEmployeeMap, entry);
      }
    },
    deletePlanningEmployeeMapEntry: function (state, payload) {
      if (state.planningEmployeeMap) {
        delete state.planningEmployeeMap[payload.id];
        state.planningEmployeeMap = Object.assign({}, state.planningEmployeeMap);
      }
    },
    deleteMultiple: function (state, payload) {
      let toRenew = [];
      payload.forEach((toDelete) => {
        let mapName = toDelete.mapName;
        let id = toDelete.id;
        if (state[mapName]) {
          delete state[mapName][id];
          if (!toRenew.includes(mapName)) {
            toRenew.push(mapName);
          }
        }
      });
      toRenew.forEach((mapName) => {
        state[mapName] = Object.assign({}, state[mapName]);
      });
    },
    updateMultiple: function (state, payload) {
      let toRenew = [];
      payload.forEach((toUpdate) => {
        let mapName = toUpdate.mapName;
        let entity = toUpdate.entity;

        if (state[mapName]) {
          state[mapName][entity.id] = entity;
          if (!toRenew.includes(mapName)) {
            toRenew.push(mapName);
          }
        }
      });
      toRenew.forEach((mapName) => {
        state[mapName] = Object.assign({}, state[mapName]);
      });
      console.log(state.planShiftMap);
    },
    setNightMode: function (state, payload) {
      state.nightMode = payload;
    },
    setRosterVisibilityStateTrue: function (state, type) {
      if (!state.rosterVisibilityState.hasOwnProperty(type)) {
        console.warn("visibility key '" + type + "' not set in default");
        return;
      }
      state.rosterVisibilityState[type] = true;
      localStorage.setItem("rosterVisibilityState", JSON.stringify(state.rosterVisibilityState));
    },
    setRosterVisibilityStateFalse: function (state, type) {
      if (!state.rosterVisibilityState.hasOwnProperty(type)) {
        console.warn("invalid visibility type: " + type);
        return;
      }
      state.rosterVisibilityState[type] = false;
      localStorage.setItem("rosterVisibilityState", JSON.stringify(state.rosterVisibilityState));
    },
    setRosterVisibilityStateToggle: function (state, type) {
      if (!state.rosterVisibilityState.hasOwnProperty(type)) {
        console.warn("invalid visibility type: " + type);
        return;
      }
      state.rosterVisibilityState[type] = !state.rosterVisibilityState[type];
      localStorage.setItem("rosterVisibilityState", JSON.stringify(state.rosterVisibilityState));
    },
    setNotificationBellConfigStateValue: function (state, payload) {
      if (payload.key == null || payload.value == null || !state.notificationBellConfigState.hasOwnProperty(payload.key)) {
        console.warn("invalid notification bell config type: " + payload.key);
        return;
      }
      state.notificationBellConfigState[payload.key] = payload.value;
      localStorage.setItem("notificationBellConfigState", JSON.stringify(state.notificationBellConfigState));
    },
    invalidateState: function (state, payload) {
      let toInvalidate = payload.states;
      toInvalidate.forEach((s) => {
        if (state[s]) {
          state[s] = {};
        }
      });
    },
    setExportSettingsMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId,
      };
      state.exportSettingsMapState = tstate;
      state.exportSettingsMap = Object.assign({}, payload.exportSettingsMap);
    },
    setExportSettingsMapEntry: function (state, payload) {
      if (state.exportSettingsMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.exportSettingsMap = Object.assign({}, state.exportSettingsMap, entry);
      }
    },
    deleteExportSettingsMapEntry: function (state, payload) {
      if (state.exportSettingsMap) {
        delete state.exportSettingsMap[payload.id];
        state.exportSettingsMap = Object.assign({}, state.exportSettingsMap);
      }
    },
    setEmployeeDocumentMap: function (state, payload) {
      let tstate = {
        companyId: state.companyId,
      };
      state.employeeDocumentMapState = tstate;
      state.employeeDocumentMap = Object.assign({}, payload.employeeDocumentMap);
    },
    setEmployeeDocumentMapEntry: function (state, payload) {
      if (state.employeeDocumentMap) {
        let entry = {};
        entry[payload.id] = payload;
        state.employeeDocumentMap = Object.assign({}, state.employeeDocumentMap, entry);
      }
    },
    deleteEmployeeDocumentMapEntry: function (state, payload) {
      if (state.employeeDocumentMap) {
        delete state.employeeDocumentMap[payload.id];
        state.employeeDocumentMap = Object.assign({}, state.employeeDocumentMap);
      }
    },
  },
  actions: {}
});
