<template>
<div>
    <div class="full">
        <div class="full">
            <div class="mainheader">
                <div class="half">
                    Dokumente
                </div>
            </div>
        </div>
        <b-container fluid>
            <div class="full split-bottom">
                <div class="label left" v-if="loading">
                    <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
                    Dokumente werden geladen<br />
                </div>

                <div class="label left" v-if="!loading">
                    <div v-if="isEmpty">
                        <h4 style="margin-top:10px;margin-bottom:10px;">
                            Es wurden noch keine Dokumente hochgeladen
                        </h4>
                    </div>
                    <div v-for="(store, key) in stores" v-bind:key="key">
                        <h4 style="margin-top:10px;margin-bottom:10px;">
                            {{storeNameAndDescription(store.documents[0].storeId)}}
                        </h4>
                        <table class="table table-striped table-borderless">
                            <tr>
                                <th class="documentsTh"></th>
                                <th class="documentsTh">Name</th>
                                <th class="documentsTh">Dokument Typ</th>
                                <th class="documentsTh" style="text-align:center;">Bestätigt</th>
                                <th class="documentsTh" style="text-align:center;">Datum</th>
                                <th class="documentsTh" style="text-align:center;">Ablaufdatum</th>
                                <th class="documentsTh" style="text-align:center;"></th>
                            </tr>
                            <tr v-for="doc in store.documents.sort((a,b) => (''+documentType(a.documentType)).localeCompare(documentType(b.documentType)))" v-bind:key="doc.id">
                              <!-- <p>{{ doc }}</p> -->
                                <td class="documentsTd"><a style="cursor:pointer;" target="_blank" v-bind:href="doc.url + '/api/file?id=' + doc.fileId + '&accessToken='+doc.accessToken"><i style="cursor:pointer;" class="far" v-bind:class="fileIconClass(doc.fileType)"></i></a></td>
                                <td class="documentsTd">{{doc.name}}</td>
                                <td class="documentsTd">{{documentType(doc.documentType)}}</td>
                                <td class="documentsTd" style="text-align:center;">
                                    <i v-if="doc.confirmed" class="fas fa-check" style="cursor:auto;" />
                                    <i v-if="!doc.confirmed" class="fas fa-times" style="cursor:auto;" />
                                </td>
                                <td class="documentsTd" style="text-align:center;">{{ $helpers.extractDateString(doc.creationDate, 'DD.MM.YYYY') }}</td>
                                <td class="documentsTd" style="text-align:center;" v-if="doc.validUntil">{{ $helpers.extractDateString(doc.validUntil, 'DD.MM.YYYY') }}</td>
                                <td class="documentsTd" style="text-align:center;" v-if="!doc.validUntil">-</td>
                                <td class="documentsTd" style="text-align:center;">
                                    <a style="cursor:pointer;" target="_blank" v-bind:href="doc.url + '/api/file?id=' + doc.fileId + '&accessToken='+doc.accessToken">
                                        <i style="cursor:pointer;" class="far fa-external-link"></i>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapGetters
} from 'vuex';
export default {
    name: 'employeedocuments',
    data() {
        return {
            loading: true,
            stores: {},
            isEmpty: true,
        }
    },
    created: function () {
        //console.log("CREATED");
    },
    mounted: function () {
        //console.log("MOUNTED");
        if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
            this.refresh();
        }
    },
    computed: {
        ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings','storeArray',]),
    },
    watch: {
        companyId: function (val, oldVal) {
            this.refresh();
        },
        employee: function (val, oldVal){
            this.loading = true;
            this.refresh();
        }
    },
    props: [
        "employee"
    ],
    // methods that implement data logic.
    methods: {
        refresh: function (force = false) {
            this.loading = true;
            let view = this;
            view.stores = {};
            let documents = [];
            view.isEmpty = true;
            view.$helpers.GetEmployeeDocumentsMap().then((response) => {
                console.log(response);
                Object.values(response).filter(x => x.remoteId == view.employee.id).forEach(doc => {
                    view.isEmpty = false;
                    if(view.stores[doc.storeId] == undefined){
                        view.stores[doc.storeId] = {};
                        view.stores[doc.storeId].documents = [];
                    }
                    view.stores[doc.storeId].documents.push(doc);
                });
                view.loading = false;
            }).catch(function (error){
                console.log(error);
                view.loading = false;
                view.$helpers.error('Fehler', 'Dokumente konnten nicht geladen werden');
            });
        },
        documentType(key){
            return this.$helpers.documentTypeLabel(key);
        },
        fileIconClass(key){
            return 'fa-' + this.$helpers.documentMimeIcon(key);
        },
        storeNameAndDescription(id){
            for(let i in this.storeArray){
                let s = this.storeArray[i];
                if(s.id == id){
                    return s.name + " / " + s.description;
                }
            }
            return "Kostenstelle nicht gefunden";
        }
    },

}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
a {
    color: var(--ml);
    cursor: pointer !important;
}

a:hover {
    color: var(--contrast-4);
    cursor: pointer !important;
}
</style>
